import React from "react";
import "./privacy.css";

const Privacy = () => {
  return (
    <div id="privacy">
      <section>
        <h5>T134 Gamer</h5>
        <h2>Privacy Policy</h2>
      </section>
      <div className="container header__container">
        <p className="text-light">
          T134Gamer values their customer’s privacy while using their Apps from
          the store. We are committed to provide safe protection of the security
          of the shared information by the user to the fully possible extent,
          within the boundaries and provision of law. We never disclosed your
          personal information without your prior consent. In this policy we
          mentioned all the terms and conditions on which we collect, store,
          share and use your personal information. Our visitor’s data privacy is
          our main responsibility. The privacy policy (“policy”) of T134Gamer
          explains how the user information is collected by us, and uses this
          information (“Personal information”) while protecting their rights
          provided on our website and any of our services/products collectively
          (“Apps” or “Services”). This policy also describes the available
          choices for using your personal information and how the information
          can be accessed and updated timely.
        </p>
        <br />
        <h3>USER CONSENT & CONTROL ON PERSONAL DATA</h3>
        <p className="text-light">
          Please read this policy carefully, as your consent is very important
          to us. You are agreeing to our terms and conditions applied on all the
          services and products you are using including Apps, while connecting
          with the devices or using the apps on the web. According to this
          policy, you are also agreeing on the collection and processing of your
          personal data used while installing our apps or using our services. If
          you as a user or guardian do not agree to our privacy policy, then you
          will not be able to use our services.
        </p>
        <br />
        <h3>PERSONAL DATA COLLECTION</h3>
        <p className="text-light">
          We accept, collect and process the fair and lawful information which
          you knowingly and willfully share with us through reviews on the Apps,
          or filling any online forms for business purposes on the website. This
          information is collected by us directly when you create an account on
          our site, manage your profile, fill any survey or any other type of
          communication. The user’s personal data and identifiers including
          advertising identifiers, device type identifiers i.e. carrier user ID,
          IP address, UDID (unique devices identifier, Media access control
          “MAC” address or Social site network ID’s. T134Gamer may receive,
          collect, process and grant access to the users specific advertising
          identifiers in compliance to the valid and relevant platform suppliers
          policies. Further may include, users email address and notification
          alerts while installing our apps through clicking on any add campaign
          and user’s GPS address from the device on which app is installed.
        </p>
        <h3>NON-PERSONAL DATA COLLECTION</h3>
        <p className="text-light">
          When you install our Apps or visit our website, the servers
          automatically detect and collect the information sent by your web
          browser. This information may include user IP address, device type and
          version, browser type, operating system version and type, user
          language preferences, may also include the websites user visited or
          the apps installed, the time spent on each site and with each app,
          information searched on the site and also other statistics. All this
          type of user information is collected by using our third party
          services. We also ensure here that no personal identity of any user is
          collected in its place, only technical and behavioral information is
          collected. This information includes software system usage, our “Apps
          or Games” our “services” different functions which a user uses and
          time duration of usage, interaction with other software users, the
          devices a user uses to get access to (device model, screen resolution,
          device operating system or any other technical information if needed).
          In addition, we may also collect user demographics i.e. location,
          interests and preferences on how and when user clicks on us ads and
          install our Applications.
        </p>
        <h3>HOW WE USE COLLECTED INFORMATION</h3>
        <p className="text-light">
          Any information or data collected by us from users may be used to
          personalize and improve the user experience, run and operate the
          services or our website. We collect non- personal data from users to
          detect potential cases of misuse/abuse and gather statistical data
          about users, their usage and traffic. This information is also used to
          detect and fix the issues in our apps, and also improve our apps for
          better user experience, for promoting our apps to users. Through this
          we improve our “Apps” providing personalized content to users and to
          enable ad servicing or similar services features. The stated
          statistical information is only collected to identify and track any
          specific user of the system and not otherwise aggregated.
        </p>
        <h3>PRIVACY POLICY FOR CHILDREN/MINORS</h3>
        <p className="text-light">
          Our products and services are not specific to any one and built for
          the general audience. We never knowingly collect, process and share
          the data of any children under age 13 without the consent of their
          parent/guardian or consistent with applicable law. If you come to know
          that we have inadvertently gathered your child’s personal data, then
          you are requested to please inform us so that we get to know about the
          matter, obtain parental consent or remove the information from our
          database. We use COPPA to get parental consent and also do not ask to
          share a child’s personal information other than the necessary terms to
          use our apps or services. We also designed games/apps under the kid’s
          category which are specifically for the kids under age 13 and these
          apps are distributed under the T134Gamer developer account. We collect
          information from children only under the Children Online Privacy
          Protection Act (COPPA) and also provide certain rights to the parents
          on this information. Such type of information is only collected in
          communication and used to support the children in their request and
          feedback. After performing the said operations, we no longer carry the
          information and remove it from our servers. Therefore, according to
          our knowledge and concern we never knowingly collect, share and store
          the personal data on our servers from children under the age of 13 and
          do not violate COPPA. Some specific information chunks are collected
          and used by our third party service providers and add networks. We
          also encourage you to protect your own personal data and data of your
          kids carefully while using third party services including social media
          platforms.
        </p>
        <h3>INFORMATION PRIVACY & SECURITY</h3>
        <p className="text-light">
          We use some technologies and procedures to help protect your personal
          data from unauthorized access, use and misuse. We have reasonable
          technical, administrative and physical security safeguards on our
          servers to protect your information in a controlled manner from
          illegal use, unauthorized access and disclosure without your consent.
          We are trying hard to protect the privacy of your personally
          identifiable information, but still we do not guarantee that any
          unauthorized activity cannot occur.
        </p>
        <h3>WHEN DO WE DISCLOSE INFORMATION?</h3>
        <p className="text-light">
          We T134Gamer may disclose your personal information we hold about our
          users only in following conditions: If required to or authorized to by
          law to disclose any specific data or by any law enforcement agency.
          When data disclosure is necessary to protect our rights, to protect
          your safety or protect the safety of other users. To prevent or avoid
          any serious emergency or crime including fraud. To our business
          partners or third party service providers in providing our services to
          you. Our business partners may include organizations i.e. advertising
          agencies, payment processors or other hosting specific services.
        </p>
        <h3>AMENDMENTS & UPDATES TO THIS POLICY</h3>
        <p className="text-light">
          We, T134Gamer, reserve the rights to change or update this policy
          linking our website, Apps or services at any time. The changes may be
          effective after posting the update on this page about the update and
          notify you on this website. Your continuous use of this site and page
          after any changes constitute your consent to all of the changes.
        </p>
        <h3>INFORMATION FROM THIRD PARTIES</h3>
        <p className="text-light">
          Sometimes we gather some information about you from third parties,
          such as google, apple or Facebook. We collect this information only
          with the consideration to improve our services and user experiences.
          <br />
          <br />
          <h5>We collect your information when:</h5>
          <p>
            When you switch from our website to another site, at that time that
            site collects your data. In such cases we have no responsibility to
            protect your privacy as you are outside the boundary of our site. We
            are also not responsible for sharing information on such sites when
            you or your child click on any link to another website. We only
            disclose or share your information with our services providers or
            companies for analytics but we never share your data with outside
            websites or companies. We therefore prohibit you from clicking on
            other site links on our website.
          </p>
          <br />
          <h5>Cookies and JavaScript:</h5>
          <p>
            Third parties are used by us for the purpose of ad services in our
            Games/Apps. These third party services providers or ad networks
            comprises technologies in their links and ads made of cookies and
            Java script. The java links and ads appeared on our website for
            advertisement and sent to the user browser directly. These ad
            networks get access to your IP address, you DID and Internet service
            provider information. These technologies are implemented to analyze
            the effectiveness of any ad campaign, visitors of any app, and to
            personalize or improve the advertising content appearing on the
            website. However, these service providers never collect your
            personally identifiable information including your Name, your Email
            id, contact number or any other relevant information.
          </p>
          <br />
          Every user holds the right to get informed about all the activities
          performed on the information collected and used by us when they
          install or play our apps/games.
        </p>
        {/* <a href="#contact" className="scroll__down">
          Scroll Down
        </a> */}
      </div>
    </div>
  );
};

export default Privacy;
