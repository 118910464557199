/* eslint-disable */
import React from "react";
import { FaFacebookF } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { IoLogoTwitter } from "react-icons/io";
import "./footer.css";

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">
        T134 GAMER
      </a>
      <div className="footer__copyright">
        {/* <small>&copy; T134 GAMER. All rights reserved.</small> */}
        <p>This site is Owned by Muhammad Irfan Saleem.
          <br/>
          Phone: +92 331 4867381
          <br/>
          Email: mohd.irfansaleem@gmail.com
          <br/>
          Address: House# 134-T, street# 7, Phase-2, DHA, Lahore.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
