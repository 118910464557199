import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from "../../assets/me-about.png";
import Socials from "./Socials";

const Header = () => {
  return (
    <header>
      <div className="container header__container">
        <img src={ME} alt="me" />
        <h1>WELCOME</h1>
        <p>
          Thanks for visiting our support website. If you have any
          issue regarding our game or have any specific suggestions in your mind
          then please write us at support@t134gamer.com. We’ll write you back as soon as possible. Thanks.
        </p>

        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
